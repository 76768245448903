import Logo from "../../../assets/Logo.png";
import Youtube from "../../../assets/youtube.png";
import LinkedIn from "../../../assets/linked-in.png";
import Twitter from "../../../assets/twitter.png";
import Facebook from "../../../assets/facebook.png";
import "../../../index.css";
import "./Footer.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TrustPilot from "../../../assets/trust-pilot.png"
import { useEffect, useState } from "react";
import FutureExpiry from '../../../assets/mar-24.png'

import TermsConditionSv from '../../../assets/documents/sv/Terms-and-Conditions-SupremeFX LLC.pdf'
import TermsConditionCom from '../../../assets/documents/com/Terms-and-Conditions-SupremeFX - Seychelles.pdf'

import PrivacyPolicySv from '../../../assets/documents/sv/Privacy Policy of Supreme FX LLC - SVG.pdf'
import PrivacyPolicyCom from '../../../assets/documents/com/Privacy Policy of Supreme FX.pdf'

// import DepositRefundSV from '../../../assets/documents/sv/Deposit-and-Refund-Policy- Supreme FX SVG.pdf'
// import DepositRefundCom from '../../../assets/documents/com/Deposit-and-Refund-Policy- Supreme FX.pdf'

// import WithdrawlPolicySv from '../../../assets/documents/sv/Withdrawal-Policy-SupremeFX LLC - SVG.pdf'
// import WithdrawlPolicyCom from '../../../assets/documents/com/Withdrawal-Policy-SupremeFX.pdf'

import TradingStyleSv from '../../../assets/documents/sv/TRADING STYLE-POLICIES Supreme FX LLC -SVG.pdf'
import TradingStyleCom from '../../../assets/documents/com/Trading Style - Supreme FX-Seychelles.pdf'

import RiskWarningSv from '../../../assets/documents/sv/Risk-Warning-Supreme FX LLC.pdf'
import RiskWarningCom from '../../../assets/documents/com/Risk-Warning-SupremeFX Seychelles.pdf'

import DisclaimerSv from '../../../assets/documents/sv/Disclaimer-Superme FX - SVG.pdf'
import DisclaimerCom from '../../../assets/documents/com/Disclaimer-Superme FX - Seychelles.pdf'

import TradingSv from '../../../assets/documents/sv/Trading Style - Supreme FX LLC.pdf'
import TradingCom from '../../../assets/documents/com/Trading Style - Supreme FX.pdf'

import ClientServiceCom from '../../../assets/documents/com/Client-Service-Agreement-Supreme-FX.pdf'
import ComplainHandlingCom from '../../../assets/documents/com/Complaint-Handling-Policy_Sun-Capital-Markets.pdf'

import CreditBonusSv from '../../../assets/documents/sv/Credit Bonus - Supreme FX LLC.pdf'
import CreditBonusCom from '../../../assets/documents/com/Credit Bonus - Supreme FX.pdf'

import DepositWithdrawCharges from '../../../assets/documents/Deposit-and-Refund-Policy-Supreme-FX 2024.pdf'

import FooterCom from './Footer-com'
import FooterSv from './Footer-sv'


const Footer = () => {
  const {t} = useTranslation();
    let listStyle = {
        "listStyle": "none"
    }
    const [termsNcondition, setTermsNcondition] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [depositNReturn, setDepositNReturn] = useState('');
    const [depositWithdraw, setDepositWithdraw] = useState('');
    const [withdrawlPolicy, setWithdrawlPolicy] = useState('');
    const [tradingStyle, setTradingStyle] = useState('');
    const [riskWarning, setRiskWarning] = useState('');
    const [disclaimer, setDisclaimer] = useState('');
    const [creditBonus, setCreditBonus] = useState('');
    const [clientService, setClientService] = useState('');
    const [complainHandling, setComplainHandling] = useState('');
    const [futureExpire, setFutureExpire] = useState('');
    const [trading, setTrading] = useState('');

    const [footerContent, setFooterContent] = useState('');

    useEffect(() =>{
      if(window.location.hostname === 'supremefxtrading.sv'){
        setTermsNcondition(TermsConditionSv);
        setPrivacyPolicy(PrivacyPolicySv);
        // setDepositNReturn(DepositRefundSV);
        setDepositWithdraw(DepositWithdrawCharges);
        // setWithdrawlPolicy(WithdrawlPolicySv);
        setTradingStyle(TradingStyleSv);
        setRiskWarning(RiskWarningSv);
        setDisclaimer(DisclaimerSv);
        setCreditBonus(CreditBonusSv);
        setTrading(TradingSv);
        // setClientService("#");
        // setComplainHandling("#");
        //setFutureExpire("http://supremefxtrading.sv/wp-content/uploads/2023/12/Future-Expiry_Dec.png");
		setFutureExpire(FutureExpiry);
        setFooterContent(<FooterSv />);
      }else if(window.location.hostname === 'supremefxtrading.net'){
		setTermsNcondition(TermsConditionSv);
        setPrivacyPolicy(PrivacyPolicySv);
        // setDepositNReturn(DepositRefundSV);
        setDepositWithdraw(DepositWithdrawCharges);
        // setWithdrawlPolicy(WithdrawlPolicySv);
        setTradingStyle(TradingStyleSv);
        setRiskWarning(RiskWarningSv);
        setDisclaimer(DisclaimerSv);
        setCreditBonus(CreditBonusSv);
		setTrading(TradingSv);
        // setClientService("#");
        // setComplainHandling("#");
        //setFutureExpire("http://supremefxtrading.sv/wp-content/uploads/2023/12/Future-Expiry_Dec.png");
		setFutureExpire(FutureExpiry);
        setFooterContent(<FooterSv />);  
	  } else {
        setTermsNcondition(TermsConditionCom);
        setPrivacyPolicy(PrivacyPolicyCom);
        // setDepositNReturn(DepositRefundCom);
        setDepositWithdraw(DepositWithdrawCharges);
        // setWithdrawlPolicy(WithdrawlPolicyCom);
        setTradingStyle(TradingStyleCom);
        setRiskWarning(RiskWarningCom);
        setDisclaimer(DisclaimerCom);
        setCreditBonus(CreditBonusCom);
        setClientService(ClientServiceCom);
        setComplainHandling(ComplainHandlingCom);
		setTrading(TradingCom);
        setFutureExpire(FutureExpiry);
        setFooterContent(<FooterCom />);
      }
    }, [])

  return (
    <div className="section footer-section pb-0 ">
      <div className="trust-pilot">
        <Link to="https://www.trustpilot.com/review/www.supremefxtrading.com" target="_blank" rel="noreferrer">
          <img src={TrustPilot} alt="" />
        </Link>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="footer-logo">
              <img src={Logo} alt="" />
            </div>
            <p>{t('By offering a unique and personal trading experience, Supreme FX is the ideal choice for our both experienced and new traders alike.')}</p>
            <div className="footer-social-icons">
              <a href="https://www.youtube.com/@supremefx9022/streams" target="_blank" rel="noreferrer">
                <img src={Youtube} alt="" className="pe-4" />
              </a>
              <a href="https://www.linkedin.com/company/supreme-fx-trading" target="_blank" rel="noreferrer">
                <img src={LinkedIn} alt="" className="pe-4" />
              </a>
              <a href="https://twitter.com/SupremeFX_Dxb" target="_blank" rel="noreferrer">
                <img src={Twitter} alt="" className="pe-4" />
              </a>
              <a href="https://www.facebook.com/SupremeFXofficial" target="_blank" rel="noreferrer">
                <img src={Facebook} alt="" className="pe-4" />
              </a>
            </div>
          </div>

          <div className="col-md-4 col-6">
            <p className="p2-medium">{t('Company')}</p>
            <div className="footer-menu" style={listStyle}>
              <ul className="p-0 m-0">
                <Link to="/">
                  <li>{t('Home')}</li>
                </Link>
                <Link to="/about/">
                  <li>{t('Company')}</li>
                </Link>
                <Link to="/account-type/">
                  <li>{t('Trading')}</li>
                </Link>
                <Link to="/introducing-broker/">
                  <li>{t('Partners')}</li>
                </Link>
                <Link to="/contact/">
                  <li>{t('Contact Us')}</li>
                </Link>
                <Link to="/faq/">
                  <li>{t('FAQ\'s')}</li>
                </Link>
              </ul>
            </div>
          </div>

          <div className="col-md-4 col-6">
            <p className="p2-medium">{t('Legal')}</p>
            <div className="footer-legal-menu" style={listStyle}>
              <ul className="p-0 m-0">
                <a href={termsNcondition} target="_blank" rel="noreferrer">
                  <li>{t('Terms & Conditions')}</li>
                </a>
                <a href={privacyPolicy} target="_blank" rel="noreferrer">
                  <li>{t('Privacy Policy')}</li>
                </a>
                {/* <a href={depositNReturn} target="_blank" rel="noreferrer">
                  <li>{t('Deposit and Refund Policy')}</li>
                </a> */}
                <a href={depositWithdraw} target="_blank" rel="noreferrer">
                  <li>{t('Deposit & Withdrawal Charges')}</li>
                </a>
                {/* <a href={withdrawlPolicy} target="_blank" rel="noreferrer">
                  <li>{t('Withdrawal Policy')}</li>
                </a> */}
                <a href={trading} target="_blank" rel="noreferrer">
                  <li>{t('Trading Style')}</li>
                </a>
                <a href={riskWarning} target="_blank" rel="noreferrer">
                  <li>{t('Risk Warning')}</li>
                </a>
                <a href={disclaimer} target="_blank" rel="noreferrer">
                  <li>{t('Disclaimer')}</li>
                </a>
                <a href={creditBonus} target="_blank" rel="noreferrer">
                  <li>{t('Credit Bonus')}</li>
                </a>
                {clientService && 
                <a href={clientService} target="_blank" rel="noreferrer">
                  <li>{t('Client service agreement')}</li>
                </a>
                }
                
                {complainHandling &&
                <a href={complainHandling} target="_blank" rel="noreferrer">
                  <li>{t('Complaint handling policy')}</li>
                </a>
                }
                
                <a href={futureExpire} target="_blank" rel="noreferrer">
                  <li>{t('Future Expiry')}</li>
                </a>
              </ul>
            </div>
          </div>
        </div>

        <hr/>

        <div className="row">
          <div className="footer-content">
                {footerContent}
          </div>


            {/* <div className="footer-content">
                <p>{t('High Risk Investment Warning: Trading foreign exchange on margin carries a high level of risk, and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to trade foreign exchange you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with foreign exchange trading, and seek advice from an independent financial adviser if you have any doubts.')}</p>

                <p>{t('Supreme FX tradename for SUPREME FX LLC is an Investment Firm incorporated under the laws of the Republic of Seychelles with company number 8431497-1. Authorized and regulated by the Financial Services Authority (FSA) in Seychelles (License No SD145). Registered address at CT House, Office 9A, Providence Mahe’, Republic of Seychelles and Clover bay Tower, Plot Number -42, Property No -1511, Business Bay, Dubai, UAE..Supreme FX LLC. is incorporated in (St. Vincent & the Grenadines) as an International Business Company with registration number No. 1497 LLC 2021. Registered address at Euro House, Richmond Hill Road, P.O. Box 2897 Kingstown, St. Vincent and the Grenadines and Clover bay Tower, Plot Number -42, Property No -1511, Business Bay, Dubai, UAE.')}</p>
                
                <p>{t('The website www.supremefxtrading.com is operated and provides content by Supreme FX group of companies, which include: • SUPREME FX LLC (Seychelles) • Supreme FX LLC (St. Vincent & the Grenadines)')}</p>
                
                <p>{t('The website www.supremefxtrading.sv is operated by Supreme FX LLC.')}</p>
                
                <p>{t('Risk Warning: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Please read the full Risk Disclosure.')}</p>
                
                <p>{t('Regional Restrictions: SUPREME FX LLC does not provide investment and ancillary services in the territories of the United States of America, Canada, Israel, Japan, North Korea, Belgium, and UN/EU Sanctioned countries.')}</p>
                
                <p>{t('30% bonus is limited to a cap of 300USD. Bonus not withdraw-able. Once bonus added, stop out margin becomes 45%. Bonus is only added as credit not to balance. Scalping trading style is prohibited.')}</p>
            </div> */}
        </div>

        <hr/>

        <div className="row">
            <div className="footer-bottom text-center">
                <p>© 2024 Supremefx Trading All rights reserved.</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
