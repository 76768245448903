import './LiveStocks.css';
import React, { useEffect, useRef, useState } from 'react';
import { getForexData, getStockData } from './forexService';
import { Link } from "react-router-dom";
import Marquee from "react-marquee-slider";
import i18next from "i18next";

const NewLiveStock = (props) => {
  const containerRef = useRef(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');
  const [velocity, setVelocity] = useState(120);

  const isMobile = () => window.innerWidth <= 768;

  useEffect(() => {
    if (i18next.language === 'ar') {
      setRedirectLang('?lang=ar');
    } else {
      setRedirectLang('?lang=en');
    }

    if (window.location.hostname === 'supremefxtrading.sv') {
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
    } else if (window.location.hostname === 'supremefxtrading.net') {
      setRegistrationLink(`https://secure.supremefxtrading.net/register`);
    } else {
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
    }

    const fetchData = async () => {
      try {
        const forexData = await getForexData();
        const stockData = await getStockData();
        console.log('stock :', stockData);
        if (forexData.result_1.code === 200) {
          if (Array.isArray(forexData.result_1.response)) {
            setData(forexData.result_1.response);
          } else {
            setError(new Error('Data 1 is not in expected format'));
          }
        } else {
          setError(new Error('Data 1 Not Found'));
        }
        if (forexData.result_2.code === 200) {
          if (Array.isArray(forexData.result_2.response)) {
            setData2(forexData.result_2.response);
          } else {
            setError(new Error('Data 2 is not in expected format'));
          }
        } else {
          setError(new Error('Data 2 Not Found'));
        }

        if (stockData.code === 200) {
          if (Array.isArray(stockData.response)) {
            setData3(stockData.response);
          } else {
            setError(new Error('Stock is not in expected format'));
          }
        } else {
          setError(new Error('Stock Not Found'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Adjust velocity for mobile devices
    if (isMobile()) {
      setVelocity(20);
    }

  }, []);

  return (
    <div className="live-stock">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <div className="section live-stock-section">
          <div className='scrollable'>
            <div className='col-md-12 mb-4'>
              <Link to={registrationLink} target="_blank">
                <div
                  onMouseOver={() => setVelocity(0)}
                  onMouseLeave={() => setVelocity(isMobile() ? 20 : 120)}
                >
                  <Marquee
                    velocity={velocity / 8}
                    direction="rtl"
                  >
                    {data.map((item, index) => (
                      <div key={index} className="forex-card">
                        <div className="title">{item.s}
                          <span className="title-right">{item.c}</span>
                        </div>
                        {data2.map((item2) => (
                          item2.symbol === item.s && (
                            <div className="subtitle" key={item2.symbol}>
                              {item2.name}
                            </div>
                          )
                        ))}
                      </div>
                    ))}
                  </Marquee>
                </div>
              </Link>
            </div>
            
            <div className='col-md-12'>
              <Link to={registrationLink} target="_blank">
                <div
                  onMouseOver={() => setVelocity(0)}
                  onMouseLeave={() => setVelocity(isMobile() ? 20 : 120)}
                >
                  <Marquee
                    velocity={velocity / 8}
                    direction="rtl"
                  >
                    {data3.map((item, index) => (
                      <div key={index} className="forex-card">
                        <div className="title">{item.s}
                          <span className="title-right">{item.c}</span>
                        </div>
                        <div className={item.ch >= 0 ? 'positive' : 'negative'}>{item.ch} ({item.cp})</div>
                      </div>
                    ))}
                  </Marquee>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewLiveStock;
