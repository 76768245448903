import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
// import Trophy from '../../assets/svg/trophy.svg'
import Trophy from '../../assets/svg/trophy.png'
// import Meeting from '../../assets/svg/meeting.svg'
import Meeting from '../../assets/svg/meeting.png'
// import AnalyzeData from '../../assets/svg/analyze-data.svg'
import AnalyzeData from '../../assets/svg/analyze-data.png'
import RewardBanner from '../../assets/reward_banner.png'
import RewardBanner2 from '../../assets/reward_banner_2.png'
import HowToJoin from '../../assets/how_to_join.png'
import Button from "../Common/Button/Button"
import "./RewardProgram.css"
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const RewardProgram = () => {
  const {t} = useTranslation();
  const [portalLink, setPortalLink] = useState('')

  const [rangeValue, setRangeValue] = useState(0)
  const [selectValue, setSelectValue] = useState(0)
  const [registrationLink, setRegistrationLink] = useState('');	
	
  const handleOnchangeSelect = (value) => {
    const deposit_value = document.getElementById('deposit_value');
    deposit_value.innerHTML = value * 10;
    setSelectValue(value);
  };

  useEffect(() => {
    if(window.location.hostname === 'supremefxtrading.sv'){
        setPortalLink("http://portal.supremefxtrading.sv/");
    } else if(window.location.hostname === 'supremefxtrading.net'){
		setPortalLink(`https://portal.supremefxtrading.net`);
	}else{
        setPortalLink("http://portal.supremefxtrading.com/");
    }
	
	if(window.location.hostname === 'supremefxtrading.sv'){
		setRegistrationLink(`https://secure.supremefxtrading.sv/register`);
	}else if(window.location.hostname === 'supremefxtrading.net'){
		setRegistrationLink(`https://secure.supremefxtrading.net/register`);
	} else{
		setRegistrationLink(`https://secure.supremefxtrading.com/register`);
	}
	
	
  }, [])

  return (
    <>
    <Navigation />
    <div className="section account-section cta-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center ">
                        <h1>{t('IB Reward Program')}</h1>
                        <p className="p2">{t('Join the SupremeFX Reward Program and earn great cash prizes!*')}</p>
						<img src={RewardBanner} alt="" className="new-img" />
						<Link to={registrationLink} target="_blank" rel="noreferrer">
                            <Button className="btn theme-red-btn" btnTxt="Open Account"></Button>
                        </Link>
						<p className="p2 pt-3">{t('All trading involves risk. It is possible to lose all your capital.')}</p>
						<p className="p2">{t('*Terms and Conditions apply.')}</p>
						
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div className="section account-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center ">
                        <h1>{t('Remember!')}</h1>
                        <h2>{t('This is a monthly Reward Plan')}</h2>
                        <p className="p2 mb-3">{t('You can find the exact dates below.')}</p>
						<table className="reward-table">
							<thead>
								<tr>
									<th>Starting From</th>
									<th>Ending On</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1 July 2021</td>
									<td>31 July 2022</td>
								</tr>
								<tr>
									<td>1 August 2022</td>
									<td>31 August 2022</td>
								</tr>
								<tr>
									<td>1 September 2022</td>
									<td>30 September 2022</td>
								</tr>
								<tr>
									<td>1 October 2022</td>
									<td>31 October 2022</td>
								</tr>
								<tr>
									<td>1 November 2022</td>
									<td>30 November 2022</td>
								</tr>
								<tr>
									<td>1 December 2022</td>
									<td>31 December 2022</td>
								</tr>
							</tbody>
						</table>
						
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div className="section account-section cta-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center ">
						<img src={RewardBanner2} alt="" className="new-img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div className="section account-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center ">
                        <h1 className="mb-4">{t('About the Rewards!')}</h1>
						<table className="reward-table-2 mb-4">
							<thead>
								<tr>
									<th>Reward Level</th>
									<th>Qualifying Net Money In (MI)</th>
									<th>Minimum Traded Lots*</th>
									<th>Cash Reward (USD)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Bronze</td>
									<td>$10,000</td>
									<td>100</td>
									<td>$450</td>
								</tr>
								<tr>
									<td>Silver</td>
									<td>$25,000</td>
									<td>250</td>
									<td>$1000</td>
								</tr>
								<tr>
									<td>Gold</td>
									<td>$50,000</td>
									<td>500</td>
									<td>$2000</td>
								</tr>
								<tr>
									<td>Platinum</td>
									<td>$100,000</td>
									<td>1000</td>
									<td>$4000</td>
								</tr>
								<tr>
									<td>Master</td>
									<td>$150,000</td>
									<td>1250</td>
									<td>$5000</td>
								</tr>
								<tr>
									<td colspan="4" className="center">*Note: The minimum traded lots include FX, commodities and metals</td>
								</tr>
								
							</tbody>
						</table>
						<p className="p2">All you need to do is meet the Net Deposits criteria <br/>and Traded Lots under your network by the end of each month as per T&Cs. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div className="section account-section cta-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center ">
						<img src={HowToJoin} alt="" className="new-img" />
						<Link to={registrationLink} target="_blank" rel="noreferrer">
                            <Button className="btn theme-red-btn mt-2" btnTxt="Join Now"></Button>
                        </Link>
						
						<p className="p2">{t('All trading involves risk. It is possible to lose all your capital.')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default RewardProgram