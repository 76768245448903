import Logo from "../../../assets/Logo.png"
import "./Header.css";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import i18next from "i18next";
// import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const languages = [
  {
    code: 'ar',
    name: "AR",
    dir: 'rtl'
  },
  {
    code: 'en',
    name: "EN",
    dir: 'ltr'
  }
];


const Navigation = () => {
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('')
  const [loginLink, setLoginLink] = useState('')
  const [redirectLang, setRedirectLang] = useState('')

  function openHam() {
    document.getElementById('root').classList.add("overfolw-hidden");
    document.getElementById("resp_nav_overlay").style.width = "100%";
    document.body.classList.add("modal-open");
    var header = document.getElementById('header-section');
    header.classList.add('hide');
    var openHam = document.getElementById("openHam");
    openHam.classList.add('d-none');

    var closeHam = document.getElementById("closeHam");
    closeHam.classList.remove('d-none');
  }

  function closeHam() {
    document.getElementById('root').classList.remove("overfolw-hidden");
    document.getElementById("resp_nav_overlay").style.width = "0%";
    document.body.classList.remove("modal-open");
    var header = document.getElementById('header-section');
    header.classList.remove('hide');

    var openHam = document.getElementById("openHam");
    openHam.classList.remove('d-none');

    var closeHam = document.getElementById("closeHam");
    closeHam.classList.add('d-none');
  }

  useEffect(() =>{
    if (i18next.language === 'ar'){
		setRedirectLang('?lead_source=website');
	} else{
		setRedirectLang('?lead_source=website');
	}

    if(window.location.hostname === 'supremefxtrading.sv'){
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
      setLoginLink(`https://secure.supremefxtrading.sv/login${redirectLang}`);
    }else if(window.location.hostname === 'supremefxtrading.net'){
		setRegistrationLink(`https://secure.supremefxtrading.net/register`);
		setLoginLink(`https://secure.supremefxtrading.net/login${redirectLang}`);
	} else{
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
      setLoginLink(`https://secure.supremefxtrading.com/login${redirectLang}`);
    }
    const dir = i18next.dir(i18next.language);
    document.body.dir = dir;
  }, [i18next, i18next.language, redirectLang])
  

  return (
	<>
	<Helmet>
		<title>SupremeFx Trading</title>
		<meta property="og:title" content="SupremeFx Trading" />
		<meta property="og:description" content="Discover the world of forex trading with our reputable brokerage firm" />
		<meta property="og:url" content={window.location.hostname} />
		<meta property="og:image" content="https://webwork.co.in/download.png" />
	</Helmet>
	
	
    <div className="header-section" id="header-section">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
        <div className="col-md-12 col-12">
          <div className="row">
            <div className="col-md-2 col-6">
              <Link className="navbar-brand" to="/">
                <img className="logo-icon" alt="" src={Logo} />
              </Link>
              <span className="languages-btn-group">
                {languages.map(({code, name}) =>(
                  <span key={code} className="ms-2" onClick={() => i18next.changeLanguage(code)}>{name}</span>
                ))}
              </span>
            </div>

            <div className="col-md-7 col-6 d-flex">
              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active">{t('Home')}</Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t('Company')}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/about/">{t('About Us')}</Link>
                      </li>

                      <li>
                        <Link className="dropdown-item" to="/company-profile/">{t('Company Profile')}</Link>
                      </li>

                      <li>
                        <Link to="/our-product/" className="dropdown-item">
                          {t('Our Products')}
                        </Link>
                      </li>
                      
                    </ul>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t('Start Trading')}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/account-type/">{t('Account Types')}</Link>
                      </li>
                      {/* <li>
                        <Link className="dropdown-item" to="/market-news/">{t('Market News')}</Link>
                      </li> */}
                      <li>
                        <Link className="dropdown-item" to="https://download.mql5.com/cdn/web/supreme.fx.llc/mt5/supreme5setup.exe" target="_blank" rel="noreferrer">{t('Download MT5')}</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t('Partnership')}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/introducing-broker/">{t('Introducing Broker')}</Link>
                      </li>
					  <li>
					  {/* <Link className="dropdown-item" to="/reward-program/">{t('Reward Program')}</Link> */}
						<Link className="dropdown-item" to="/">{t('Reward Program')}</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to='/faq/'>{t('FAQ')}</Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/contact/" className="nav-link">{t('Contact Us')}</Link>
                  </li>
                </ul>
              </div>

              <div className="hamburger" id="openHam" onClick={openHam}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
              </div>

              <div className="hamburger d-none" id="closeHam" onClick={closeHam}>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="accounts-link">
                <Link to={loginLink} target="_blank" rel="noreferrer">{t('Login')}</Link>
                <Link to={registrationLink} target="_blank" rel="noreferrer">
                  <Button className="theme-pink-btn" btnTxt={t('Open Account')} />
                </Link>
              </div>
            </div>

          </div>
        </div>
        </div>
      </nav>

      <div id="resp_nav_overlay" className="resp_nav_overlay d-flex align-items-center" style={{"width": "0%"}}>
        <div className="overlay-content">
            <div className="container">
                <div className="">
                    <ul className="navbar-nav navbar-nav-scroll">
                      <li className="nav-item">
                        <Link to="/" className="nav-link" onClick={closeHam}>{t('Home')}</Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t('Company')}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="/about/" onClick={closeHam}>{t('About Us')}</Link>
                          </li>

                          <li>
                            <Link className="dropdown-item" to="/company-profile/" onClick={closeHam}>{t('Company Profile')}</Link>
                          </li>

                          <li>
                            <Link to="/our-product/" className="dropdown-item" onClick={closeHam}>
                              {t('Our Products')}
                            </Link>
                          </li>
                          
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t('Start Trading')}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="/account-type/" onClick={closeHam}>{t('Account Types')}</Link>
                          </li>
                          {/* <li>
                            <Link className="dropdown-item" to="/market-news/" onClick={closeHam}>{t('Market News')}</Link>
                          </li> */}
                          <li>
                            <Link className="dropdown-item" to="https://download.mql5.com/cdn/web/supreme.fx.llc/mt5/supreme5setup.exe" onClick={closeHam}>{t('Download MT5')}</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t('Partnership')}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="/introducing-broker/" onClick={closeHam}>{t('Introducing Broker')}</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to='/faq/' onClick={closeHam}>{t('FAQ')}</Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/contact/" className="nav-link" onClick={closeHam}>{t('Contact Us')}</Link>
                      </li>
                        
                    </ul>

                    <div className="mobile-login-btn">
                      <Link to={loginLink} target="_blank" rel="noreferrer">
                      <Button className="btn theme-light-btn w-100" btnTxt={t("Login")} />
                      </Link>
                      <Link to={registrationLink} target="_blank" rel="noreferrer">
                      <Button className="btn theme-red-btn w-100" btnTxt={t("Open Account")} />
                      </Link>
                    </div>

                    <span className="languages-btn-group d-block">
                    {languages.map(({code, name}) =>(
                      <span key={code} className="ms-2" onClick={() => i18next.changeLanguage(code)}>{name}</span>
                    ))}
                    </span>
                </div>
            </div>
        </div>
    </div>

    </div>
	</>
  );
};

export default Navigation;
