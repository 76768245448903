import Navigation from '../Common/Header/Header'
import Footer from '../Common/Footer/Footer'
import Accordion from './Accordion'
import FingerPrintAuth from '../../assets/svg/fingerprint-auth.svg'
import './FAQ.css'
import Button from '../Common/Button/Button'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const FAQ = () => {
    const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('')

    useEffect(() => {
        if(window.location.hostname === 'supremefxtrading.netlify.app'){
            setRegistrationLink('https://secure.supremefxtrading.sv/register');
        } else if(window.location.hostname === 'supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      } else{
            setRegistrationLink('https://secure.supremefxtrading.com/register');
        }
      }, [])
    
  return (
    <>
    <Navigation />
    <div className='section faq-section'>
        <div className='container'>
            <div className='row'>
            <div className='heading'>
                <h2>{t('FAQ')}</h2>
            </div>
            </div>

            <div className='row'>
            <Accordion />
            </div>

            <div className='row'>
                <div className='faq-cta'>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-12 order-lg-1 order-2">
                                <div className="footer-logo">
                                <img className="" src={FingerPrintAuth} />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 content">
                                <h5 className='mb-0'>Try Supreme FX for free today</h5>
                                <div className="features-list">
                                    <p className='mb-0'>Powerful investment platform to help you grow and manage your money.</p>
                                </div>

                                <div className="mt-5 feature-btn">
                                    <a href={registrationLink} target='_blank' rel='noreferrer'>
                                    <Button className="theme-red-btn" btnTxt="Open Account" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </>
  )
}

export default FAQ