import React from "react";
import Button from "../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";

const AccountTypeCard = (props) => {
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');

  useEffect(() => {
    if (i18next.language === 'ar'){
		setRedirectLang('?lead_source=website');
	} else{
		setRedirectLang('?lead_source=website');
	}

    if(window.location.hostname === 'supremefxtrading.sv'){
        setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
      }else if(window.location.hostname === 'supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      } 
	  else{ 
        setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
      }
}, [i18next.language, redirectLang])
  
  return (
    <div className="col-md-4">
      <div className="card">
        <h5>{props.title}</h5>
        <ul>
            {props.content.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#D90700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#D90700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

               <p className="p2">{item}</p>
              </li>
            ))}
        </ul>
        <div className="account-type-btns">
            <a href={registrationLink} target="_blank" rel="noreferrer">
              <Button className="w-100 mb-2 theme-red-btn" btnTxt={t("Open an Account")} />
            </a>
            <a href={registrationLink} target="_blank" rel="noreferrer">
              <Button className="theme-light-btn text-white dark-x" btnTxt={t("Halal Account")} />
            </a>
        </div>
      </div>
    </div>
  );
};

export default AccountTypeCard;
