import Zendesk, { ZendeskAPI } from "./ZendexConfig";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import AccountType from './components/AcountType/AccountType';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import FAQ from './components/FAQ/FAQ';
import Accounts from './components/Accounts/Accounts';
import OurProduct from './components/OurProduct/OurProduct';
import MarketNews from './components/MarketNews/MarketNews';
import IntroducingBroker from './components/IntroducingBroker/IntroducingBroker';
import RewardProgram from './components/RewardProgram/RewardProgram';
import CompanyProfile from './components/CompanyProfile/CompanyProfile';
import ScrollToTop from './components/Common/ScrollToTop';
const ZENDESK_KEY = "79686bdf-5922-4638-9259-61f183a96f74";


function App() {
  const handleLoaded = () => {
    ZendeskAPI("messenger", "show");
  };

  return (
    <>
    <div>
    
    </div>
    <BrowserRouter>
    <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
    <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/account-type/' element={<AccountType />} />
        <Route path='/about/' element={<About />} />
        <Route path='/faq/' element={<FAQ />} />
        <Route path='/contact/' element={<Contact />} />
        <Route path='/account/' element={<Accounts />} />
        <Route path='/our-product/' element={<OurProduct />} />
        <Route path='/market-news/' element={<MarketNews />} />
        <Route path='/introducing-broker/' element={<IntroducingBroker />} />
        <Route path='/reward-program/' element={<RewardProgram />} />
        <Route path='/company-profile/' element={<CompanyProfile />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
